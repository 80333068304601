export default [
  {
    state: 'done',
    text: 'пройден',
  },
  {
    state: 'timeout',
    text: 'закрыт',
  },
  {
    state: 'active',
    text: 'активен',
  },
  {
    state: 'none',
    text: 'доступен',
  },
  {
    state: 'deleted',
    text: 'удален',
  },
]