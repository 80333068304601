<template>
  <div class="up-courses">
    <section v-if="activeCourses.length > 0" class="up-courses__section">
      <h2 class="up-courses__title">Доступные курсы</h2>
      <div class="up-courses__items">
        <router-link
          v-for="c in activeCourses"
          :key="c.id"
          :to="`/listener/course/${c.id}`"
          class="up-courses__item"
        >
          <h3 class="up-courses__item-name">Курс {{ c.name }}</h3>
          <div class="up-courses__item-info">
            <span>{{ c.lessons | wordDeclension("урок") }}</span>
            <el-tag type="success">
              {{ flowStateTranslated(c.flowState) }}
            </el-tag>
          </div>
        </router-link>
      </div>
    </section>
    <section v-if="completedCourses.length > 0" class="up-courses__section">
      <h2 class="up-courses__title">Завершенные курсы</h2>
      <div class="up-courses__items">
        <router-link
          v-for="c in completedCourses"
          :key="c.id"
          :to="`/listener/course/${c.id}`"
          class="up-courses__item"
        >
          <h3 class="up-courses__item-name">Курс {{ c.name }}</h3>
          <div class="up-courses__item-info">
            <span>{{ c.lessons | wordDeclension("урок") }}</span>
            <el-tag type="success">
              {{ flowStateTranslated(c.flowState) }}
            </el-tag>
          </div>
        </router-link>
      </div>
    </section>
    <p v-if="showEmptyText" class="up-empty-list-text">Тут появятся доступные Вам курсы</p>
  </div>
</template>

<script>
import courseStatuses from "@/enums/courseStatuses";

import { mapActions } from "vuex";

export default {
  name: "Listener",
  data: () => ({
    courses: [],
    loading: true,
  }),
  computed: {
    activeCourses() {
      return this.courses.filter((c) => c.flowState === "active" || c.flowState === "none");
    },
    completedCourses() {
      return this.courses.filter(
        (c) => c.flowState === "done" || c.flowState === "timeout"
      );
    },
    showEmptyText() {
      return (
        !this.completedCourses.length &&
        !this.activeCourses.length &&
        !this.loading
      );
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
    flowStateTranslated(flowState) {
      const status = courseStatuses.find((s) => s.state === flowState);
      return status ? status.text : "";
    },
    async fetchCourses() {
      this.$nprogress.start();

      try {
        const courses = await this.$axios.get("/listener/flowsShort");

        this.courses = courses.data;
      } catch (e) {
        //
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
  },
  created() {
    this.fetchCourses();

    this.setNavigationText("Список всех курсов");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/listener/index.scss";
</style>